import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import Seo from '../../components/seo'
import Navigation from '../../components/Navigation'
import { Container, MainContent, Title, Subtitle } from '../../utils/styles'
import { useInViewportAnimation } from '../../utils/hooks'
import Footer from '../../components/Footer'
import { Grid, LayerOnTop } from './styles'

const Page = ({
  title,
  subtitle,
  children,
  headerImage,
  container = true,
  color = 'transparent',
}) => {
  const image = getImage(headerImage)
  useInViewportAnimation()

  return (
    <div style={{ backgroundColor: color }}>
      <Seo title={title} thumbnail={headerImage?.publicURL} />
      <Navigation />

      {image ? (
        <>
          <Grid>
            <GatsbyImage
              style={{
                gridArea: '1/1',
                minHeight: 300,
              }}
              loading="eager"
              image={image}
              alt=""
            />
            <LayerOnTop>
              <Container
                style={{
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div>
                  <Title>{title}</Title>
                  <Subtitle>{subtitle}</Subtitle>
                </div>
              </Container>
            </LayerOnTop>
          </Grid>

          {container ? (
            <Container>
              <MainContent id="main">{children}</MainContent>
            </Container>
          ) : (
            <MainContent id="main">{children}</MainContent>
          )}
        </>
      ) : (
        <Container>
          <Title style={{ marginTop: '120px' }} color="black">
            {title}
          </Title>
          <MainContent id="main">{children}</MainContent>
        </Container>
      )}

      <Footer />
    </div>
  )
}

export default Page
