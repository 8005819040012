import styled from '@emotion/styled'

export const Grid = styled.div`
  display: grid;
`

export const LayerOnTop = styled.div`
  grid-area: 1/1;
  position: relative;
  width: 100%;
`
